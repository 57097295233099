import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import state, * as fromState from './state'
import ui, * as fromUi from './ui'
import auth, * as fromAuth from './auth'
import blackList from './blackList'
import features, * as fromFeatures from './features'
import agents, * as fromAgents from './agents'
import cobrand, * as cobrands from './cobrand'
import customerServices, * as fromCustomerServices from './customerServices'
import rate, * as fromRates from './rate'
import games, * as fromGames from './games'
import liveGames, * as fromLiveGames from './liveGames'
import teams, * as fromTeams from './teams'
import reports, * as fromReports from './reports'
import theme, * as themeFn from './theme'
import msgs from './msgs'
import requestState, * as requestStateFn from './requestState'
import brandInfo, * as brandFn from './brand'
import promo, * as fromPromo from './promo'
import live from './live'
import betThreshold from './betThreshold'
import order from './order'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  state,
  ui,
  auth,
  blackList,
  features,
  agents,
  cobrand,
  customerServices,
  rate,
  games,
  liveGames,
  teams,
  reports,
  theme,
  msgs,
  requestState,
  brandInfo,
  promo,
  live,
  betThreshold,
  order,
})

export default createRootReducer

export const getTimeZone = state => fromState.getTimeZone(state.state)
export const getLanguage = state => fromState.getLanguage(state.state)

export const getScroll = state => fromUi.getScroll(state.ui)

export const getUser = state => fromAuth.getUser(state.auth)
export const getUserInfo = state => fromAuth.getUserInfo(state.auth)
export const getUserTotalPlayers = state => fromAuth.getUserTotalPlayers(state.auth)
export const getIsLogged = state => fromAuth.getIsLogged(state.auth)
export const getIsLogin = state => fromAuth.getIsLogin(state.auth)
export const getIsLogout = state => fromAuth.getIsLogout(state.auth)
export const getErrorMessage = state => fromAuth.getErrorMessage(state.auth)
export const getBrandFromUserInfo = state => fromAuth.getBrandFromUserInfo(state.auth)

export const getSiteFeatures = state =>
  fromFeatures.getSiteFeatures(state.features)

export const getAgents = state => fromAgents.getAgents(state.agents)
export const getAgentOptions = state => fromAgents.getAgentOptions(state.agents)
export const getAgentById = (state, agentId) =>
  fromAgents.getAgentById(state.agents, agentId)
export const getAgentTotalPlayersById = (state, agentId) =>
  fromAgents.getAgentTotalPlayersById(state.agents, agentId)
export const getIsFetchingAgents = state =>
  fromAgents.getIsFetchingAgents(state.agents)
export const getAgentListWithRole = state =>
  fromAgents.getAgentListWithRole(state.agents)

export const getIsCobrandFetching = state =>
  cobrands.getIsFetching(state.cobrand)

export const getCustomerServices = state =>
  fromCustomerServices.getCustomerServices(state.customerServices)
export const getCustomerServiceById = (state, customerServiceId) =>
  fromCustomerServices.getCustomerServiceById(
    state.customerServices,
    customerServiceId,
  )
export const getIsFetchingCustomerServices = state =>
  fromCustomerServices.getIsFetching(state.customerServices)

export const getCurrencyOptions = state =>
  fromRates.getCurrencyOptions(state.rate)

// 遊戲列表（篩選體彩相關）
export const getGamesWithoutLottoAndSport = (state, language) =>
  fromGames.getGamesWithoutLottoAndSport(state.games, language)
export const getGamesOnlyLottoAndSport = (state, language) =>
  fromGames.getGamesOnlyLottoAndSport(state.games, language)

// 真人遊戲列表（篩選體彩相關）
export const getLiveGamesWithoutLottoAndSport = (state, language) =>
  fromLiveGames.getLiveGamesWithoutLottoAndSport(state.liveGames, language)
export const getLiveGamesOnlyLottoAndSport = (state, language) =>
  fromLiveGames.getLiveGamesOnlyLottoAndSport(state.liveGames, language)

// 遊戲列表（全部）
export const getGameList = (state, language) => state.games[language]

export const getTeams = state => fromTeams.getTeams(state.teams)
export const getSelectTeam = state => fromTeams.getSelectTeam(state.teams)
export const getTeamMembersByTeamId = (state, teamId) =>
  fromTeams.getTeamMembersByTeamId(state.teams, teamId)
export const getTeamFeaturesByTeamId = (state, teamId) =>
  fromTeams.getTeamFeaturesByTeamId(state.teams, teamId)
export const getIsTeamFeaturesFetching = (state, teamId) =>
  fromTeams.getIsTeamFeaturesFetching(state.teams, teamId)

export const getReportByFilter = (state, filter) =>
  fromReports.getReportByFilter(state.reports, filter)

export const getIsFetchingByFilter = (state, filter) =>
  fromReports.getIsFetchingByFilter(state.reports, filter)

export const getTheme = state => themeFn.getTheme(state.theme)

// msgs
export const getMsgs = state => state.msgs

// request state
export const getIsRequesting = (state, eventName) => requestStateFn.getIsRequesting(state.requestState, eventName)

// brand
export const getBrandInfo = state => brandFn.getBrandInfo(state)

// promo list
export const getPromoList = state => fromPromo.getPromo(state.promo, 'promoList')

// promo period
export const getPromoPeriod = state => fromPromo.getPromo(state.promo, 'promoPeriod')

// live
export const getLiveTableTypeOption = (state) => state.live.tableTypeOption
export const getLiveTableIdOption = (state) => state.live.tableIdOption

// black list
export const getBlackList = state => state.blackList

// bet threshold
export const getBetThresholdGroupList = state => state.betThreshold.betThresholdGroup
export const getBetThresholdIsSubmitting = state => state.betThreshold.isSubmitting
