import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  getUser,
  getSiteFeatures,
  getIsLogged,
  getScroll,
  getLanguage,
  getTimeZone,
  getSelectTeam,
  getTheme,
  getMsgs,
  getBrandInfo,
  getBrandFromUserInfo,
} from '../../reducers'
import a from '../../actions'
import { getSavedState, storageTypes } from '../../helpers/storage'
import { withSnackbar } from 'notistack'

import AppBar from '../../components/AppBar'
import AppDrawer from '../../components/AppDrawer'
import Dashboard from '../Dashboard'
import LoginCard from '../LoginCard'
import PrivateRoute from '../../components/PrivateRoute'
import DialogConfirm from '../../components/DialogConfirm'

import AgentDetail from '../AgentDetail'
import AgentDetailForAdmin from '../AgentDetailForAdmin'
import CustomerServiceDetail from '../CustomerServiceDetail'
import UserProfile from '../UserProfile'
import TeamFeature from '../TeamFeature'
import TeamMember from '../TeamMember'
import Debug from '../Debug'
import { DARK_MODE, LIGHT_MODE } from '../../themes/bo'

import { withTranslation } from 'react-i18next'
import ScrollToTop from '../../components/ScrollToTop'
import { api as apis, wsClient } from '../../services'

class App extends Component {
  componentDidMount () {
    const {
      token,
      language,
      timezone,
      setUserByToken,
      setLanguage,
      setTimezone,
      i18n,
      getBrandInfo,
      brandInfo,
    } = this.props

    if (!brandInfo.brand) {
      getBrandInfo()
    }

    // 用於重整時
    // - 設定 API header
    // - 建立、註冊 websocket 實體
    if (token) {
      // 設定 API header
      apis.setHeaders(apis.RP, { usertoken: token, Authorization: token })
      apis.setHeaders(apis.BO, { usertoken: token })
      apis.setHeaders(apis.BO2, { usertoken: token })

      setUserByToken({ token })

      this.connectWs()
    }

    language && i18n.changeLanguage(language).then(() => { setLanguage({ language }) })
    timezone == null && setTimezone({ timezone: -4 })
  }

  componentDidUpdate (prevProps) {
    const {
      user,
      fetchSiteFeatures,
      fetchRateList,
      fetchGameList,
      fetchLiveGameList,
      fetchOrder,
      enqueueSnackbar,
      removeMessage,
      msgs,
      token,
      brandInfo,
      t,
    } = this.props

    // 左下方消息推播
    // 由此處統一處理（字典檔為 /locale/announcement）
    // TODO: 將散布於系統各處的推播透過改寫
    if (msgs.length > 0) {
      msgs.forEach(msg => {
        const m = msg.replaceWithI18nText ? t(msg.replaceWithI18nText, { ns: 'announcement', defaultValue: '' }) : msg.msg

        enqueueSnackbar(m || 'Something wrong', {
          variant: msg.variant,
        })
      })

      removeMessage({ num: msgs.length })
    }

    // 在此週期使用 JSON 比較會影響效能
    if (JSON.stringify(prevProps.user) !== JSON.stringify(user) && user) {
      fetchSiteFeatures()
      fetchRateList({ userId: user.userID })
    }

    if (
      (user && prevProps.brandInfo.brand !== brandInfo.brand) ||
      (JSON.stringify(prevProps.user) !== JSON.stringify(user) && brandInfo.brand)
    ) {
      fetchGameList({ brand: brandInfo.brand })
      fetchLiveGameList({ brand: brandInfo.brand })
      fetchOrder()
    }

    // 登入時建立、註冊 websocket 實體
    if (token && token !== prevProps.token) {
      this.connectWs()
    }
  }

  connectWs () {
    const { fetchSiteFeatures, token } = this.props

    wsClient.Connect(token)
    wsClient.Register([
      {
        name: 'teamFeature',
        event: () => {
          fetchSiteFeatures()
        },
      },
    ])
  }

  handleLogout = () => {
    this.props.logout()
  };

  handleChangeLanguage = (language, i18n) => {
    i18n.changeLanguage(language).then(() => { this.props.setLanguage({ language }) })
  };

  handleScroll = () => {
    const { setScroll } = this.props

    setScroll({ status: false })
  };

  fetchTeamMembers = () => {
    const { selectedTeam, fetchTeamMembers } = this.props

    if (selectedTeam) {
      fetchTeamMembers({ teamId: selectedTeam.id })
    }
  };

  fetchTeamFeatures = () => {
    const { selectedTeam, fetchTeamFeatures } = this.props

    if (selectedTeam) {
      fetchTeamFeatures({ teamId: selectedTeam.id })
    }
  };

  renderRoot = () => {
    const { from, isLogged } = this.props

    if (isLogged && from) {
      return <Redirect to={from} />
    }
    return isLogged ? (
      <PrivateRoute form isLogged={isLogged} component={() => <></>} />
    ) : (
      <Route render={p => <LoginCard {...p}/>} />
    )
  };

  render () {
    const {
      t,
      user,
      timezone,
      language,
      isLogged,
      scroll,
      setTimezone,
      themeMode,
      switchTheme,
      brandInfo,
      siteFeatures,
      brandfromUserInfo,
    } = this.props
    return (
      <>
        <AppBar
          user={user}
          timezone={timezone}
          language={language}
          handleSetTimezone={timezone => setTimezone({ timezone })}
          handleLogout={this.handleLogout}
          handleChangeLanguage={this.handleChangeLanguage}
          isLogged={isLogged}
          handleSwitchTheme={() => {
            switchTheme({ mode: themeMode === DARK_MODE ? LIGHT_MODE : DARK_MODE })
          }}
        />

        <div style={{ display: 'flex' }}>
          {isLogged && (
            <AppDrawer
              items={siteFeatures.mappedItems.menu}
              brand={brandfromUserInfo}
            />
          )}

          <ScrollToTop scroll={scroll} onScroll={this.handleScroll}>
            <Switch>
              <PrivateRoute
                isLogged={isLogged}
                path={'/dashboard'}
                component={Dashboard}
              />

              <PrivateRoute
                isLogged={isLogged}
                path={'/agentDetail/:agentId'}
                component={AgentDetail}
              />
              <PrivateRoute
                isLogged={isLogged}
                path={'/agentDetailForAdmin/:agentId'}
                component={AgentDetailForAdmin}
              />
              <PrivateRoute
                isLogged={isLogged}
                path={'/customerServiceDetail/:customerServiceId'}
                component={CustomerServiceDetail}
              />
              <PrivateRoute
                isLogged={isLogged}
                path={'/userProfile'}
                component={UserProfile}
              />
              <PrivateRoute
                isLogged={isLogged}
                path={'/teamFeatures'}
                component={TeamFeature}
              />
              <PrivateRoute
                isLogged={isLogged}
                path={'/teamMembers'}
                component={TeamMember}
              />
              <PrivateRoute
                isLogged={isLogged}
                path={'/debug'}
                component={Debug}
              />

              {siteFeatures.mappedItems.routes
                .filter(i => !i.menu)
                .map((route, index) => (
                  <PrivateRoute
                    isLogged={isLogged}
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    routePage={route.key}
                  />
                ))}

              {this.renderRoot()}
            </Switch>
          </ScrollToTop>
          <DialogConfirm
            open={siteFeatures.noFeatures}
            title={t('error')}
            content={t('noFeaturesContent')}
            onSubmit={this.handleLogout}
            confirmText={t('logout')}
            confirmColor="alert"
            fullWidth={true}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const from = location.state ? location.state.from : null

  const token = getSavedState('token')
  const user = getUser(state)
  const language = getSavedState('language') || getLanguage(state)
  const timezone = getTimeZone(state) ?? getSavedState(storageTypes.TIMEZONE)
  const scroll = getScroll(state)
  const selectedTeam = getSelectTeam(state)
  const siteFeatures = getSiteFeatures(state)
  const themeMode = getTheme(state)
  const msgs = getMsgs(state)
  const isLogged = getIsLogged(state)
  const brandInfo = getBrandInfo(state)

  const brandfromUserInfo = getBrandFromUserInfo(state)

  return {
    themeMode,
    token,
    from,
    user,
    msgs,
    scroll,
    timezone,
    language,
    selectedTeam,
    siteFeatures,
    isLogged,
    brandInfo,
    brandfromUserInfo,
  }
}

export default connect(
  mapStateToProps,
  {
    // auth
    logout: a.auth.logout,
    setUserByToken: a.auth.setUserByToken,
    // brand
    getBrandInfo: a.brandInfo.get,
    getBrandFromUserInfo: a.brandInfo.getFromUserInfo,
    // game
    fetchGameList: a.game.fetchGameList,
    fetchLiveGameList: a.liveGame.fetchLiveGameList,

    // msgs
    removeMessage: a.msgs.remove,
    // rate
    fetchRateList: a.rate.fetch,
    // site feature
    fetchSiteFeatures: a.siteFeature.fetch,
    // language
    setLanguage: a.language.set,
    // team member
    fetchTeamMembers: a.teamMember.fetch,
    // team feature
    fetchTeamFeatures: a.teamFeature.fetch,
    // time
    setTimezone: a.time.setTimezone,
    // ui
    setScroll: a.ui.setScroll,
    switchTheme: a.ui.switchTheme,
    fetchOrder: a.order.fetchOrder,
  },
)(withTranslation()(withSnackbar(App)))
